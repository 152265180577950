import React, { FC } from "react";
import styled from "styled-components";

import { toVWMobile, toVWDesktop, toREM } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import { Image } from "../../types";
import Link from "../shared/Link";

export type NewsroomAssetsProps = {
  isPublished?: boolean;
  title: string;
  cards: CardType[];
};

type CardType = {
  link: string;
  image: Image;
  cardTitle: string;
};

const getizeRatio = (text: string) => {
  if (text?.length > 10) {
    return 0.7;
  }
  return 1;
};

const NewsroomAssets: FC<NewsroomAssetsProps> = ({
  isPublished,
  title,
  cards,
}: NewsroomAssetsProps) => {
  if (!isPublished || !cards?.length) return null;

  return (
    <Container>
      <Title>{title}</Title>
      <CardWrapper>
        {cards?.map((card, index) => (
          <Card key={index} to={card?.link}>
            <PictureWrapper>
              <Picture src={card?.image?.src} alt={card?.image?.alt} />
            </PictureWrapper>
            <CardTitle ratio={getizeRatio(card?.cardTitle)}>{card?.cardTitle}</CardTitle>
          </Card>
        ))}
      </CardWrapper>
    </Container>
  );
};

const Container = styled.section`
  background-color: #033305;
  padding: ${toVWMobile(60)}vw 0;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(60)}vw 0;
  }
`;

const Title = styled.h4`
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(44)}rem;
  font-weight: 900;
  line-height: ${toVWMobile(53)}vw;
  color: #ffffff;
  letter-spacing: 0.012em;
  margin-bottom: ${toVWMobile(151)}vw;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(56)}rem;
    line-height: ${toVWDesktop(64)}vw;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    margin-bottom: ${toVWDesktop(118)}vw;
  }
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Card = styled(Link)`
  background-color: #012102;
  padding: 0 ${toVWMobile(20)}vw;
  height: ${toVWMobile(305)}vw;
  text-decoration: none;

  &:not(:last-child) {
    margin-bottom: ${toVWMobile(140)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(427)}vw;
    padding: 0 ${toVWDesktop(20)}vw;
    width: ${toVWDesktop(400)}vw;
  }
`;

const PictureWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${toVWMobile(200)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(300)}vw;
  }
`;

const Picture = styled.img`
  height: ${toVWMobile(289)}vw;
  width: ${toVWMobile(289)}vw;
  object-fit: cover;
  position: relative;
  top: ${toVWMobile(-60)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(352)}vw;
    width: ${toVWDesktop(352)}vw;
    top: ${toVWDesktop(-50)}vw;
  }
`;

const CardTitle = styled.p<{ ratio: number }>`
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${(props) => props.ratio * toREM(36)}rem;
  font-weight: 900;
  line-height: ${toVWMobile(40)}vw;
  color: #ffffff;
  letter-spacing: 0.03em;
  margin-top: ${toVWMobile(10)}vw;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${(props) => props.ratio * toREM(47)}rem;
    letter-spacing: 0.02em;
    line-height: ${toVWDesktop(40)}vw;
    margin-top: ${toVWDesktop(20)}vw;
  }
`;

export default NewsroomAssets;
