import React, { FC } from "react";
import styled from "styled-components";
import SVG from "react-inlinesvg";

import {
  toVWMobile,
  toVWDesktop,
  toREM,
  vwDesktop,
} from "../../helpers/styles";
import { BREAKPOINTS, MEDIA_DESKTOP } from "../../helpers/theme";
import Link from "../shared/Link";

import RightArrow from "../../assets/images/carousel_right_arrow.svg";
import PressReleaseCard, { PressReleaseCardProps } from "../PressReleaseCard";

export type PressReleasesProps = {
  link: { label: string; link: string };
  cards: PressReleaseCardProps[];
  readMore: string;
};

const PressReleases: FC<PressReleasesProps> = ({
  link,
  cards,
  readMore,
}: PressReleasesProps) => {
  return (
    <Container>
      <CardWrapper>
        {cards?.map((card, index) => (
          <PressReleaseCard key={index} {...card} readMore={readMore} />
        ))}
      </CardWrapper>
      <LinkContainer>
        <BottomLink to={link?.link} color="#75C154">
          {link?.label}
          <Arrow src={RightArrow} color="#75C154" />
        </BottomLink>
      </LinkContainer>
    </Container>
  );
};

const Container = styled.section`
  background-color: #033305;
  width: 100%;
`;

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  gap: ${toVWMobile(20)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${toVWDesktop(80)}vw ${toVWDesktop(10)}vw;
  }
`;

const PressReleaseGreenTitle = styled.label`
  color: #75c154;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-weight: 800;
  font-size: ${toREM(18)}rem;
  line-height: ${toVWMobile(22)}vw;
  justify-self: start;
  letter-spacing: 0.08em;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    line-height: ${toVWDesktop(22)}vw;
  }

  &.hover {
    color: #033305;
  }
`;

const PressReleaseDate = styled(PressReleaseGreenTitle)`
  color: #ffffff;

  &.hover {
    color: #033305;
  }
`;

const PressReleaseDescription = styled.p`
  color: #ffffff;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(24)}rem;
  font-weight: 900;
  line-height: ${toVWMobile(29)}vw;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  grid-column: 1 / -1;
  margin-top: ${toVWMobile(10)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    line-height: ${toVWDesktop(29)}vw;
    margin-top: ${toVWDesktop(10)}vw;
  }

  &.hover {
    color: #033305;
    font-weight: 700;
    margin-bottom: ${toVWDesktop(70)}vw;
  }
`;

const LinkContainer = styled.div`
  direction: ltr;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const BottomLink = styled(Link)<{ color?: string }>`
  color: ${(props) => (props.color ? props.color : "#033305")};
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(16)}rem;
  font-weight: 700;
  grid-column: 2/3;
  letter-spacing: ${toREM(0.16)}rem;
  text-decoration: none;
  text-align: end;
  width: fit-content;
  margin-left: auto;
  transition: all 0.2s;

  &:hover {
    cursor: pointer;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      border-bottom: ${toVWDesktop(4)}vw solid currentColor;
    }
  }

  ${MEDIA_DESKTOP} {
    height: ${vwDesktop(24)};
    margin-bottom: ${toVWDesktop(20)}vw;
    margin-top: ${toVWDesktop(70)}vw;
  }
`;

const Arrow = styled(SVG)<{ color?: string }>`
  margin-left: ${toVWMobile(8)}vw;
  width: ${toVWMobile(20)}vw;
  height: ${toVWMobile(11)}vw;
  z-index: 1;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-left: ${toVWDesktop(8)}vw;
    width: ${toVWDesktop(20)}vw;
    height: ${toVWDesktop(11)}vw;
  }

  & path {
    fill: ${(props) => props.color};
  }
`;

export default PressReleases;
