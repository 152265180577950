import React, { FC, useCallback, useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import { ROUTES } from "../../helpers/routes";
import { searchInObject } from "../../helpers/search";
import { toREM, toVWDesktop, toVWMobile } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import { Tag } from "../../types";
import NewsroomAssets, { NewsroomAssetsProps } from "../NewsroomAssets";
import PressReleases from "../PressReleases";
import { PressReleasesProps } from "../PressReleases";
import SearchTags, { TagType } from "../SearchTags";
import SendNote, { SendNoteProps } from "../SendNote";
import { getLink, openLink } from "../../helpers/link";

export type NewsroomProps = {
  title: string;
  allPressReleaseLabel: string;
  searchPlaceholder: string;
  readMore: string;
  tags: TagType[];
  selectedTag: string;
  articles: Array<PressReleasesProps & { tags: Tag[] }>;
  assets: NewsroomAssetsProps[];
  contacts: SendNoteProps[];
};

const Newsroom: FC<NewsroomProps> = ({
  title,
  searchPlaceholder,
  allPressReleaseLabel,
  readMore,
  tags,
  selectedTag,
  articles,
  assets,
  contacts,
}: NewsroomProps) => {
  const [activeTag, setActiveTag] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleActiveTagChange = useCallback(
    (activeTagIndex: number) => {
      setActiveTag(activeTagIndex);
    },
    [setActiveTag]
  );

  useEffect(() => {
    setActiveTag(tags?.findIndex(({ value }) => value === selectedTag) || 0);
  }, []);

  const filteredArticles = useMemo(() => {
    return articles
      .filter(
        (pRelease) =>
          (searchTerm ? searchInObject(pRelease, searchTerm) : true) &&
          pRelease?.tags?.find(
            (tag) =>
              tag.value === tags[activeTag]?.value || !tags[activeTag]?.value
          )
      )
      .slice(0, 6);
  }, [activeTag, searchTerm, articles]);

  const link = useMemo((): { label: string; link: string } => {
    return {
      link: `${ROUTES.PRESS_RELEASES}?tag=${encodeURIComponent(
        tags[activeTag]?.value
      )}&query=${encodeURIComponent(searchTerm)}`,
      label: allPressReleaseLabel,
    };
  }, [activeTag, searchTerm]);

  const handleSearchTermChange = useCallback(
    (term: string) => {
      setSearchTerm(term);
    },
    [setSearchTerm]
  );

  const handleKeyPress = useCallback(
    (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        openLink(getLink(link.link));
      }
    },
    [link]
  );

  return (
    <Container>
      <Title>{title}</Title>
      <SearchTags
        tags={tags}
        onSearchTermChange={handleSearchTermChange}
        searchTerm={searchTerm}
        searchPlaceholder={searchPlaceholder}
        setActive={handleActiveTagChange}
        onInputKeyPress={handleKeyPress}
        active={activeTag}
      />
      <PressReleases link={link} cards={filteredArticles} readMore={readMore} />
      <NewsroomAssets {...assets} />
      <SendNote {...contacts} />
    </Container>
  );
};

const Container = styled.section`
  background-color: #033305;
  padding: 0 ${toVWMobile(20)}vw ${toVWMobile(60)}vw;
  width: 100%;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: 0 ${toVWDesktop(100)}vw ${toVWDesktop(60)}vw;
  }
`;

const Title = styled.h3`
  background-color: #033305;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(36)}rem;
  font-weight: 900;
  line-height: ${toVWMobile(40)}vw;
  color: #ffffff;
  letter-spacing: 0.03em;
  margin-bottom: ${toVWMobile(40)}vw;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(56)}rem;
    line-height: ${toVWDesktop(64)}vw;
    text-transform: uppercase;
    margin-bottom: ${toVWDesktop(40)}vw;
  }
`;

export default Newsroom;
