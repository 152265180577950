import React, { FC, useState, useMemo, useCallback, useEffect } from "react";
import styled from "styled-components";
import SVG from "react-inlinesvg";

import { toVWMobile, toVWDesktop, toREM } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import { ROUTES } from "../../helpers/routes";

import SearchTags, { TagType } from "../SearchTags";
import RightArrow from "../../assets/images/carousel_right_arrow.svg";
import { Tag } from "../../types";
import BlogHero, { BlogHeroType } from "../BlogHero";
import BlogCard, { BlogCardProps } from "../BlogCard";
import Link from "../shared/Link";
import { searchInObject } from "../../helpers/search";
import { getLink, openLink } from "../../helpers/link";

export type BlogType = {
  isPublished?: boolean;
  allArticlesLabel: string;
  allTag: string;
  searchPlaceholder: string;
  tags: TagType[];
  hero: BlogHeroType;
  readMore: string;
  articles: Array<BlogCardProps & { tags: Tag[] }>;
};
const Blog: FC<BlogType> = ({
  isPublished,
  allArticlesLabel,
  allTag,
  searchPlaceholder,
  tags,
  hero,
  articles,
  readMore,
}: BlogType) => {
  const [activeTag, setActiveTag] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleActiveTag = useCallback(
    (activeTagIndex: number) => {
      setActiveTag(activeTagIndex);
    },
    [setActiveTag]
  );

  const filteredArticles = useMemo(() => {
    return articles
      .filter(
        (blog) =>
          (searchTerm ? searchInObject(blog, searchTerm) : true) &&
          blog?.tags?.find(
            (tag) =>
              tag.value === tags[activeTag]?.value || !tags[activeTag]?.value
          )
      )
      .slice(
        0,
        tags[activeTag]?.label?.toLowerCase() === allTag?.toLowerCase() ? 3 : 6
      );
  }, [activeTag, searchTerm, articles]);

  const link = useMemo((): { label: string; link: string } => {
    return {
      link: `${ROUTES.ARTICLES}?tag=${encodeURIComponent(
        tags[activeTag]?.value
      )}&query=${encodeURIComponent(searchTerm)}`,
      label: allArticlesLabel,
    };
  }, [activeTag, searchTerm]);

  const handleSearchTermChange = useCallback(
    (term: string) => {
      setSearchTerm(term);
    },
    [setSearchTerm]
  );

  const handleKeyPress = useCallback(
    (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        openLink(getLink(link.link));
      }
    },
    [link]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <Container>
      <SearchTagsWrapper>
        <SearchTags
          searchPlaceholder={searchPlaceholder}
          tags={tags}
          searchTerm={searchTerm}
          onSearchTermChange={handleSearchTermChange}
          setActive={handleActiveTag}
          active={activeTag}
        />
      </SearchTagsWrapper>
      {tags[activeTag]?.label?.toLowerCase() === allTag?.toLowerCase() &&
        isPublished && (
          <BlogHeroWrapper>
            <BlogHero {...hero} />
          </BlogHeroWrapper>
        )}
      <CardsContainer>
        <BlogCardWrapper>
          {filteredArticles?.map((card, index) => (
            <BlogCard key={index} {...card} readMore={readMore} />
          ))}
        </BlogCardWrapper>
        <LinkContainer>
          <BottomLink to={link?.link} color="#75C154">
            {link?.label}
            <Arrow src={RightArrow} color="#75C154" />
          </BottomLink>
        </LinkContainer>
      </CardsContainer>
    </Container>
  );
};

const Container = styled.section`
  background-color: #033305;
  width: 100%;
`;

const SearchTagsWrapper = styled.div`
  padding: 0 ${toVWMobile(19)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: 0 ${toVWDesktop(100)}vw;
  }
`;

const BlogHeroWrapper = styled.div`
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: 0 0 ${toVWDesktop(100)}vw ${toVWDesktop(100)}vw;
  }
`;

const CardsContainer = styled.div`
  padding: 0 ${toVWMobile(19)}vw ${toVWMobile(40)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(11)}vw ${toVWDesktop(100)}vw ${toVWDesktop(80)}vw;
  }
`;

const BlogCardWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: ${toVWDesktop(80)}vw;
  }
`;

const LinkContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    border-bottom: 3px solid #2b6447;
  }
`;

const BottomLink = styled(Link)<{ color?: string }>`
  direction: ltr;
  color: ${(props) => (props.color ? props.color : "#033305")};
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(16)}rem;
  font-weight: 700;
  grid-column: 2/3;
  letter-spacing: ${toREM(0.16)}rem;
  text-decoration: none;
  text-align: end;
  width: fit-content;
  margin-left: auto;
  transition: all 0.2s;

  &:hover {
    cursor: pointer;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      border-bottom: ${toVWDesktop(4)}vw solid currentColor;
    }
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(20)}vw;
    margin-bottom: ${toVWDesktop(20)}vw;
    margin-top: ${toVWDesktop(70)}vw;
  }
`;

const Arrow = styled(SVG)<{ color?: string }>`
  margin-left: ${toVWMobile(8)}vw;
  width: ${toVWMobile(20)}vw;
  height: ${toVWMobile(11)}vw;
  z-index: 1;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-left: ${toVWDesktop(8)}vw;
    width: ${toVWDesktop(20)}vw;
    height: ${toVWDesktop(11)}vw;
  }

  & path {
    fill: ${(props) => (props.color ? props.color : "#75C154")};
  }
`;

export default Blog;
