import React, { FC } from "react";
import styled, { css } from "styled-components";

import { toVWMobile, toVWDesktop, toREM } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";

export type SendNoteProps = {
  isPublished?: boolean;
  title: string;
  contacts: ContactType[];
};

type ContactType = {
  name: string;
  email: string;
};

const SendNote: FC<SendNoteProps> = ({
  title,
  contacts,
  isPublished,
}: SendNoteProps) => {
  if (!isPublished || !contacts?.length) return null;

  return (
    <Container>
      <Title>{title}</Title>
      <ContactWrapper>
        {contacts?.map((contact, index) => (
          <Contact key={index}>
            <ContactName>{contact?.name}</ContactName>
            <ContactEmail href={`mailto:${contact?.email}`}>
              {contact?.email}
            </ContactEmail>
          </Contact>
        ))}
      </ContactWrapper>
    </Container>
  );
};

const Container = styled.section`
  background-color: #033305;
  padding: ${toVWMobile(60)}vw 0 0;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(20)}vw 0 0;
  }
`;

const Title = styled.h4`
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(36)}rem;
  font-weight: 900;
  line-height: ${toVWMobile(40)}vw;
  color: #ffffff;
  letter-spacing: 0.03em;
  margin-bottom: ${toVWMobile(40)}vw;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    line-height: ${toVWDesktop(40)}vw;
    text-transform: uppercase;
    margin-bottom: ${toVWDesktop(40)}vw;
  }
`;

const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    flex-direction: row;
  }
`;

const Contact = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: ${toVWMobile(20)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      margin-bottom: 0;
      margin-right: ${(props) =>
        props.theme.isRTL ? 0 : `${toVWDesktop(30)}vw`};
      margin-left: ${(props) =>
        props.theme.isRTL ? `${toVWDesktop(30)}vw` : 0};
    }
  }
`;

const ContactName = styled.p`
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(24)}rem;
  font-weight: 900;
  line-height: ${toVWMobile(31)}vw;
  color: #ffffff;
  letter-spacing: 0.06em;
  margin-bottom: ${toVWMobile(5)}vw;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    line-height: ${toVWDesktop(31)}vw;
    text-transform: uppercase;
    margin-bottom: ${toVWDesktop(5)}vw;
  }
`;

const ContactEmail = styled.a`
  cursor: pointer;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-weight: 900;
  line-height: ${toVWMobile(24)}vw;
  color: #75c154;
  letter-spacing: 0.08em;
  margin-bottom: ${toVWMobile(5)}vw;
  text-transform: uppercase;
  text-decoration: none;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    line-height: ${toVWDesktop(24)}vw;
    text-transform: uppercase;
    margin-bottom: ${toVWDesktop(5)}vw;
  }
`;

export default SendNote;
