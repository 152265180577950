import React, { FC } from "react";
import styled from "styled-components";

import { toVWMobile, toVWDesktop, toREM } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import Tabs from "../Tabs";
import Newsroom from "../Newsroom";
import { NewsroomAssetsProps } from "../NewsroomAssets";
import { SendNoteProps } from "../SendNote";
import Blog, { BlogType } from "../Blog";
import { Tag, TagType } from "../../types.d.ts";
import { BlogCardProps } from "../BlogCard";
import { PressReleaseCardProps } from "../PressReleaseCard";
import { isArticleValid } from "../../helpers/article";

export type NewsRoomBlogTabsProps = {
  articles: PressReleaseCardProps[] | BlogCardProps[];
  assetsData: NewsroomAssetsProps;
  contactUsData: SendNoteProps;
  tags: Tag[];
  selectedTag: string;
  allPressReleaseLabel: string;
  searchPlaceholder: string;
  pressTabTitle: string;
  title: string;
  readMore: string;
  blog: BlogType;
};

const NewsRoomBlogTabs: FC<NewsRoomBlogTabsProps> = ({
  articles,
  assetsData,
  contactUsData,
  tags,
  selectedTag,
  allPressReleaseLabel,
  searchPlaceholder,
  pressTabTitle,
  title,
  readMore,
  blog,
}: NewsRoomBlogTabsProps) => {
  const filterTagsByType = (tags: Tag[], type: string) => {
    if (!type) return tags;
    return tags?.filter((tag) => tag?.type === type);
  };

  const filterArticlesByType = (
    articles: PressReleaseCardProps[] | BlogCardProps[],
    type: string
  ) => {
    if (!type) return articles;
    return articles?.filter((a) => a.articleType === type && isArticleValid(a));
  };

  const sortArticles = (articles) =>
    articles?.sort(
      (a, b) => new Date(b?.date).getTime() - new Date(a?.date).getTime()
    );

  const pressReleaseArticles = sortArticles(
    filterArticlesByType(articles, TagType.PRESS_RELEASE)
  );

  const blogArticles = sortArticles(
    filterArticlesByType(articles, TagType.BLOG)
  );

  const pressReleaseTags = filterTagsByType(tags, TagType.PRESS_RELEASE);
  const blogTags = filterTagsByType(tags, TagType.BLOG);

  const modifiedNewsroomData = {
    ...assetsData,
    ...contactUsData,
    selectedTag,
    articles: pressReleaseArticles,
    tags: pressReleaseTags,
    allPressReleaseLabel,
    searchPlaceholder,
    title,
    readMore,
  };

  const modifiedBlogData = {
    ...blog,
    articles: blogArticles,
    tags: [{ label: blog.allTag, value: "" }, ...blogTags],
    readMore,
  };

  return (
    <Container>
      <Tabs
        tabs={[
          {
            id: "press_release",
            tag: "H2",
            title: pressTabTitle,
            children: <Newsroom {...modifiedNewsroomData} />,
          },
          {
            id: "blog",
            tag: "H2",
            title: blog?.tabTitle,
            children: <Blog {...modifiedBlogData} />,
          },
        ]}
      />
    </Container>
  );
};

const Container = styled.div`
  & .tab-container {
    background-color: #033305;
    justify-content: flex-start;
    padding: ${toVWMobile(30)}vw ${toVWMobile(19)}vw ${toVWMobile(40)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      padding: ${toVWDesktop(100)}vw ${toVWDesktop(100)}vw ${toVWDesktop(80)}vw;
    }
  }

  & .tab {
    height: ${toVWMobile(28)}vw;
    font-weight: 800;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      font-weight: 900;
      font-size: ${toREM(80)}rem;
      line-height: ${toVWDesktop(5)}vw;
      height: ${toVWDesktop(105)}vw;
    }
  }
`;

export default NewsRoomBlogTabs;
