import { graphql } from "gatsby";
import { useIntl } from "../hooks/useIntl";
import React, { useCallback } from "react";
import Page from "../components/Page";

import { formatDate } from "../helpers/date";
import { TagType } from "../types.d.ts";

import NewsRoomBlogTabs from "../components/NewsRoomBlogTabs";

import { getTranslations } from "../helpers/translations";
import { getAssetURL } from "../helpers/assets";
import SEO from "../components/SEO";
import { parseSEO } from "../helpers/seo";
import { isPublished } from "../helpers/directus";
import { useStaticLabels } from "../hooks/useStaticLabels";
import { getArticleAssetData } from "../helpers/article";

const NewsRoom = ({ data }: { data: any }) => {
  const intl = useIntl();
  const labels = useStaticLabels();

  const getRootTranslatedData = useCallback(() => {
    return getTranslations(
      data,
      "directus.article_landing_page.translations",
      intl.locale
    );
  }, [data]);

  const getContactUsData = useCallback(() => {
    const translatedData = getRootTranslatedData();

    return {
      contacts: {
        isPublished: isPublished(translatedData?.contact_us_status),
        title: translatedData?.contact_us_title,
        contacts: translatedData?.contact_us_locations?.map(
          (contact: { email: string; location: string }) => ({
            name: contact?.location,
            email: contact?.email,
          })
        ),
      },
    };
  }, [getRootTranslatedData, intl]);

  const getAssetsData = useCallback(() => {
    const translatedData = getRootTranslatedData();
    const articleData: any = getArticleAssetData(labels);

    return {
      assets: {
        isPublished: translatedData?.assets_status,
        title: labels?.assetsTitle,
        cards: translatedData?.assets_card_list
          ?.map((card: any, index: number) => {
            const translatedCard = getTranslations(
              card,
              "assets_card_id.translations",
              intl.locale
            );

            const { link, title } = articleData[index] ?? {};

            return {
              link,
              image: {
                src: getAssetURL(translatedCard?.assets_image),
                alt: translatedCard?.assets_image_alt,
              },
              cardTitle: title,
              status: translatedCard?.status,
            };
          })
          ?.filter(({ status }: { status: string }) => isPublished(status)),
      },
    };
  }, [getRootTranslatedData, intl]);

  const getBlogData = useCallback(() => {
    const translatedData = getRootTranslatedData();

    const translatedMainBlogData = getTranslations(
      translatedData,
      "main_blog.translations",
      intl.locale
    );

    return {
      isPublished: isPublished(translatedData?.blog_status),
      allArticlesLabel: labels?.allBlogsTitle,
      searchPlaceholder: labels?.allBlogsSearchPlaceholder,
      tabTitle: labels?.blogTabTitle,
      allTag: labels?.blogAllTag,
      hero: {
        isPublished: isPublished(translatedMainBlogData?.status),
        image: {
          src: getAssetURL(translatedMainBlogData?.article_image),
          alt: translatedMainBlogData?.article_image_alt,
        },
        thumbnail: {
          src: getAssetURL(translatedMainBlogData?.article_thumbnail),
          alt: translatedMainBlogData?.article_thumbnail_alt,
        },
        post: "Blog post,",
        date: formatDate(translatedMainBlogData?.article_date),
        description: translatedMainBlogData?.article_title,
        button: {
          label: labels?.readMoreLabel,
          link: `/whats-new/${translatedData?.main_blog?.article_slug}`,
        },
      },
    };
  }, [getRootTranslatedData, intl]);

  const getTagsListData = useCallback(() => {
    const tags = data?.directus?.article_tag?.map((tag: any) => {
      const { tag_name = "" } =
        getTranslations(tag, "translations", intl.locale) || {};

      return {
        label: tag_name,
        value: tag_name,
        type: tag?.tag_type?.type_value,
      };
    });

    return {
      tags,
    };
  }, [data, intl]);

  const getSelectedTag = useCallback(() => {
    const translatedData = getRootTranslatedData();
    const translatedMainTag =
      getTranslations(
        translatedData,
        "newsroom_main_tag.translations",
        intl.locale
      ) || {};

    return translatedMainTag?.tag_name;
  }, [getRootTranslatedData, intl]);

  const getNewsroomData = useCallback(() => {
    const articles = data?.directus?.article
      ?.map((a: any) => {
        const {
          article_file = "",
          article_image,
          article_image_alt,
          article_thumbnail,
          article_thumbnail_alt,
          article_description,
          article_title,
          article_date,
          tags,
          status,
          info_status,
        } = getTranslations(a, "translations", intl.locale) || {};

        const translatedTags = tags?.map((tag: any) => {
          const { tag_name = "" } =
            getTranslations(tag, "article_tag_id.translations", intl.locale) ||
            {};
          return {
            label: tag_name,
            value: tag_name,
          };
        });

        return {
          status: status,
          infoStatus: info_status,
          date: formatDate(article_date),
          articleFile: article_file,
          image: { src: getAssetURL(article_image), alt: article_image_alt },
          thumbnail: { src: getAssetURL(article_thumbnail), alt: article_thumbnail_alt },
          description: article_description,
          type:
            a?.article_type?.type_value === TagType.BLOG
              ? "blog post"
              : "press release",
          title: article_title,
          slug: a?.article_slug,
          articleType: a?.article_type?.type_value,
          tags: translatedTags,
        };
      })
      ?.filter(
        (a: any) => isPublished(a?.status) && isPublished(a?.infoStatus)
      );

    return {
      articles,
    };
  }, [data, intl]);

  const getNewsroomBlogTabsData = useCallback(() => {
    const { articles } = getNewsroomData();
    const { tags } = getTagsListData();
    const assetsData = getAssetsData();
    const contactUsData = getContactUsData();
    const blogData = getBlogData();
    const selectedTag = getSelectedTag();

    return {
      articles,
      assetsData,
      contactUsData,
      tags,
      selectedTag,
      allPressReleaseLabel: labels?.allPressReleasesTitle,
      searchPlaceholder: labels?.allPressSearchPlaceholder,
      title: labels?.newsroomTitle,
      pressTabTitle: labels?.newsroomTabTitle,
      readMore: labels?.readMoreLabel,
      blog: blogData,
    };
  }, [data, intl]);

  return (
    <Page
      headerBackgroundColor={"#033305"}
      helmet={<SEO {...parseSEO(getRootTranslatedData(), intl?.locale)} />}
    >
      <NewsRoomBlogTabs {...getNewsroomBlogTabsData()} />
    </Page>
  );
};

export default NewsRoom;

export const query = graphql`
  query ($locale: String) {
    directus {
      article_landing_page {
        translations(filter: { languages_code: { code: { _eq: $locale } } }) {
          seo_information {
            ...SEO
          }
          id
          newsroom_status
          newsroom_main_tag {
            translations(
              filter: { languages_code: { code: { _eq: $locale } } }
            ) {
              languages_code {
                code
                name
              }
              tag_name
            }
          }
          blog_status
          status
          languages_code {
            name
            code
          }
          main_blog {
            article_slug
            article_type {
              type_value
              translations(
                filter: { languages_code: { code: { _eq: $locale } } }
              ) {
                languages_code {
                  name
                  code
                }
                article_type_label
              }
            }
            id
            translations(
              filter: { languages_code: { code: { _eq: $locale } } }
            ) {
              languages_code {
                name
                code
              }
              status
              tags {
                article_tag_id {
                  tag_type {
                    type_value
                    translations(
                      filter: { languages_code: { code: { _eq: $locale } } }
                    ) {
                      languages_code {
                        name
                        code
                      }
                      article_type_label
                    }
                  }
                  translations(
                    filter: { languages_code: { code: { _eq: $locale } } }
                  ) {
                    languages_code {
                      name
                      code
                    }
                    tag_name
                  }
                }
              }
              info_status
              article_title
              article_description
              article_date
              article_image_alt
              article_image {
                id
                filename_disk
              }
              article_thumbnail_alt
              article_thumbnail {
                id
                filename_disk
              }
              article_file {
                id
                filename_disk
              }
              article_content
              article_questions_title
              article_questions_accordion
            }
          }
          assets_status
          assets_card_list {
            assets_card_id {
              translations(
                filter: { languages_code: { code: { _eq: $locale } } }
              ) {
                languages_code {
                  name
                  code
                }
                assets_image_alt
                status
                assets_image {
                  id
                  filename_disk
                }
              }
            }
          }
          contact_us_status
          contact_us_title
          contact_us_locations
        }
      }
      article(limit: -1) {
        article_slug
        article_type {
          type_value
          translations(filter: { languages_code: { code: { _eq: $locale } } }) {
            languages_code {
              name
              code
            }
            article_type_label
          }
        }
        id
        translations(filter: { languages_code: { code: { _eq: $locale } } }) {
          seo_information {
            ...SEO
          }
          languages_code {
            name
            code
          }
          status
          tags {
            article_tag_id {
              tag_type {
                type_value
                translations(
                  filter: { languages_code: { code: { _eq: $locale } } }
                ) {
                  languages_code {
                    name
                    code
                  }
                  article_type_label
                }
              }
              translations(
                filter: { languages_code: { code: { _eq: $locale } } }
              ) {
                languages_code {
                  name
                  code
                }
                tag_name
              }
            }
          }
          info_status
          article_title
          article_description
          article_date
          article_image_alt
          article_image {
            id
            filename_disk
          }
          article_thumbnail_alt
          article_thumbnail {
            id
            filename_disk
          }
          article_file {
            id
            filename_disk
          }
          article_content
          article_questions_title
          article_questions_accordion
        }
      }
      article_tag {
        tag_type {
          type_value
          status
          id
          translations(filter: { languages_code: { code: { _eq: $locale } } }) {
            id
            languages_code {
              code
              name
            }
            article_type_label
          }
        }
        id
        translations(filter: { languages_code: { code: { _eq: $locale } } }) {
          id
          languages_code {
            code
            name
          }
          tag_name
        }
      }
    }
  }
`;
