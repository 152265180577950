import React, { FC } from "react";
import { ROUTES } from "../../helpers/routes";
import { Image, Tag } from "../../types";
import AnimatedCard from "../AnimatedCard";
import PlaceholderImage from "../../assets/images/press-release-placeholder.png";
import { useStaticLabels } from "../../hooks/useStaticLabels";

export type BlogCardProps = {
  thumbnail: Image;
  type?: string;
  date?: string;
  title?: string;
  readMore: string;
  tags?: Tag[];
  slug?: string;
  description?: string;
};

const BlogCard: FC<BlogCardProps> = ({
  thumbnail,
  type,
  date,
  title,
  slug,
  readMore,
  description,
}: BlogCardProps) => {
  const labels = useStaticLabels();

  return (
    <AnimatedCard
      link={`${ROUTES.BLOG}/${slug}`}
      image={{
        ...thumbnail,
        src: thumbnail?.src || PlaceholderImage,
        alt: thumbnail?.alt || labels?.article,
      }}
      readMore={readMore}
      type={type}
      date={date}
      title={title}
      typeColor="#ccb983"
      description={description}
    />
  );
};
export default BlogCard;
